"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  "1": "PCIM System 后台管理系统",
  "2": "账号",
  "3": "密码",
  "4": "记住我",
  "5": "登 录",
  "6": "登 录 中...",
  "7": "刷新",
  "8": "项目编号",
  "9": "项目名称",
  "10": "客户名称",
  "11": "船名",
  "12": "联系人",
  "13": "项目地点",
  "14": "进厂时间",
  "15": "离厂时间",
  "16": "销售负责人",
  "17": "协调负责人",
  "18": "项目状态",
  "19": "用户名不能为空",
  "20": "密码不能为空"
};