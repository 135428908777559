"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  "1": "Plate Cooler Intelligence Management System",
  "2": "Account",
  "3": "Password",
  "4": "remember me",
  "5": "login",
  "6": "Logging in...",
  "7": "Refresh",
  "8": "Project Number",
  "9": "entry name",
  "10": "Customer Name",
  "11": "Ship name",
  "12": "contacts",
  "13": "Project location",
  "14": "Entry time",
  "15": "Departure time",
  "16": "Sales Manager",
  "17": "Coordination leader",
  "18": "Project Status",
  "19": "The username cannot be empty",
  "20": "Password cannot be empty"
};