"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("@voerkai18n/vue2");
var _default = exports.default = {
  mixins: [(0, _vue.i18nMixin)()],
  data: function data() {
    return {};
  },
  computed: {
    currentLanguages: function currentLanguages() {
      return localStorage.getItem('language');
    }
  },
  methods: {
    handleChangeLanguage: function handleChangeLanguage(name) {
      (0, _vue.i18nMixin)().methods.changeLanguage(name);
      window.location.reload();
    }
  }
};