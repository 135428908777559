"use strict";

var _interopRequireDefault = require("/Users/baopengcheng/IdeaProjects/pcm-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.t = exports.i18nScope = void 0;
var _typeof2 = _interopRequireDefault(require("/Users/baopengcheng/IdeaProjects/pcm-web/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _defineProperty2 = _interopRequireDefault(require("/Users/baopengcheng/IdeaProjects/pcm-web/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _idMap = _interopRequireDefault(require("./idMap.js"));
var _runtime = require("@voerkai18n/runtime");
var _zh = _interopRequireDefault(require("./formatters/zh.js"));
var _zh2 = _interopRequireDefault(require("./zh.js"));
var _storage = _interopRequireDefault(require("./storage.js"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } /**
 * 注意：执行compile命令会重新生成本文件，所以请不要修改本文件
 */ // 语言ID映射文件
// 默认语言格式化器
var messages = {
  'zh': _zh2.default,
  'en': function en() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("./en.js"));
    });
  }
};
var formatters = {
  'zh': _zh.default,
  'en': function en() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("./formatters/en.js"));
    });
  }
};

// 语言配置文件
var scopeSettings = {
  "languages": [{
    "name": "zh",
    "title": "中文",
    "default": true,
    "active": true
  }, {
    "name": "en",
    "title": "Enlish"
  }],
  "namespaces": {}
};

// 语言作用域
var scope = exports.i18nScope = new _runtime.VoerkaI18nScope(_objectSpread({
  id: "pcm-web",
  // 当前作用域的id，自动取当前工程的package.json的name
  debug: false,
  // 是否在控制台输出调试信息   
  idMap: _idMap.default,
  // 消息id映射列表        
  library: false,
  // 开发库时设为true
  messages: messages,
  // 语言包+
  formatters: formatters,
  // 扩展自定义格式化器    
  storage: _storage.default
}, scopeSettings));
// 翻译函数
var scopedTtranslate = exports.t = _runtime.translate.bind(scope);