"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleChangeLanguage
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "changeLanguage"
    }
  })], 1), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.languages, function (lng) {
    return _c("el-dropdown-item", {
      key: lng.title,
      attrs: {
        disabled: _vm.currentLanguages === lng.name,
        command: lng.name
      }
    }, [_vm._v("\n      " + _vm._s(lng.title) + "\n    ")]);
  }), 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;