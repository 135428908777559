"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  "PCIM System 后台管理系统": 1,
  "账号": 2,
  "密码": 3,
  "记住我": 4,
  "登 录": 5,
  "登 录 中...": 6,
  "刷新": 7,
  "项目编号": 8,
  "项目名称": 9,
  "客户名称": 10,
  "船名": 11,
  "联系人": 12,
  "项目地点": 13,
  "进厂时间": 14,
  "离厂时间": 15,
  "销售负责人": 16,
  "协调负责人": 17,
  "项目状态": 18,
  "用户名不能为空": 19,
  "密码不能为空": 20
};