"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  get: function get(key) {
    // eslint-disable-next-line no-undef
    if (globalThis.localStorage) {
      // eslint-disable-next-line no-undef
      return globalThis.localStorage.getItem(key);
    }
  },
  set: function set(key, value) {
    // eslint-disable-next-line no-undef
    if (globalThis.localStorage) {
      // eslint-disable-next-line no-undef
      globalThis.localStorage.setItem(key, value);
    }
  },
  remove: function remove(key) {
    // eslint-disable-next-line no-undef
    if (globalThis.localStorage) {
      // eslint-disable-next-line no-undef
      globalThis.localStorage.removeItem(key);
    }
  }
};